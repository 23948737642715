<template>
  <div>
    <Frame :loading="loading" :title-action="getTitleAction()" title="Recibos">
      <div slot="actions">
        <span @click="$router.push({ name: 'payrollReceipts' })" class="close-frame">
          <i class="mdi mdi-close"></i>
        </span>
      </div>

      <Layout v-if="payrollInformation">
        <Column size="3">
          Periodo:
          <span>{{
            `${payrollInformation.idPaymentPeriod}-${
              payrollInformation.calculationNumber
            }/${payrollInformation.payrollSchemaDescription.slice(0, 3)}`
          }}</span>
        </Column>
        <Column size="3"> RFC del colaborador: {{ payrollInformation.employeeRFC }} </Column>
        <Column size="3"> Periodicidad: {{ payrollInformation.paymentPeriodicity }} </Column>
        <Column size="3"> Código postal: {{ payrollInformation.codigoPostal }}</Column>
      </Layout>
      <TabStrip
        v-if="payrollInformation"
        :setSelected="getCurrentSchema()"
        @change="onChange"
        ref="tabs"
      >
        <TabStripItem
          :key="index"
          :title="transform(schema.payrollSchemaDescription)"
          v-for="(schema, index) in payrollInformation.schemas"
        >
          <Layout style="margin-bottom: 8px">
            <Column size="4">
              Empresa:
              {{ `${payrollInformation.companyRFC} - ${payrollInformation.companyLegalName}` }}
            </Column>
            <Column size="4">
              <div class="row-overflow">
                Registro patronal: {{ payrollInformation.employerRegistration }}
              </div>
            </Column>
            <Column size="4">
              <div class="row-overflow">
                Tipo de contrato: {{ payrollInformation.contractType }}
              </div>
            </Column>
          </Layout>

          <Layout style="margin-bottom: 16px">
            <Column size="4">
              Régimen:
              {{
                `${payrollInformation.regymeTypeCode} - ${payrollInformation.regymeTypeDescription}`
              }}
            </Column>
            <Column size="4"> Fecha de pago: {{ payrollInformation.paymentDate }} </Column>
            <Column size="4">
              Estatus:
              <span :class="`pw-tag is-${getStatusColor(payrollInformation.statusCode)}`">
                {{ payrollInformation.statusDescription }}
              </span>
            </Column>
            <div class="error-detail">
              <img style="padding-right: 10px" src="../../../../assets/alert-outline.svg" alt="" />
              <p>{{ payrollInformation.error }}</p>
            </div>
          </Layout>

          <Layout>
            <Column size="8">
              <div
                class="calculation-actions"
                v-if="hasConcepts"
                :class="{
                  block: cannotChange || !$can('AddPerdeducConcept', 'STM') || !implemented,
                }"
              >
                <span>{{ `No. de cálculo: ${calculationNumber}` }}</span>

                <Button
                  :disabled="calculationNumber === 1"
                  @click="getPayrollInfoByCalculationNo(-1)"
                  class="custom-button"
                  color="secondary-outlined"
                  icon="chevron-left"
                  v-bind:class="{
                    'custom-button__disabled': calculationNumber === 1,
                  }"
                />
                <Button
                  :disabled="calculationNumber === payrollInformation.noOfCalculations"
                  @click="getPayrollInfoByCalculationNo(1)"
                  class="custom-button"
                  color="secondary-outlined"
                  icon="chevron-right"
                  v-bind:class="{
                    'custom-button__disabled':
                      calculationNumber === payrollInformation.noOfCalculations,
                  }"
                />
              </div>
            </Column>
          </Layout>

          <Layout v-if="!globalData">
            <Column size="12">
              <Dropdown
                position="bottom-right"
                class="kardex-actions"
                :class="{
                  block: cannotChange || !$can('AddPerdeducConcept', 'STM') || !implemented,
                }"
                v-if="availableActions"
              >
                <DropdownItem @click="onStamp" v-if="$can('StampPayroll', 'STM') && canStamp">
                  Timbrar
                </DropdownItem>
                <DropdownItem
                  @click="onPreviousReceipts"
                  v-if="$can('ViewPayrolls', 'STM') && hasPrevious"
                >
                  Ver recibos anteriores
                </DropdownItem>
                <DropdownItem @click="onDeleteConcepts" v-if="$can('DeletePerdeducConcept', 'STM')">
                  Eliminar conceptos
                </DropdownItem>
                <DropdownItem @click="onDelete" v-if="$can('DeletePayroll', 'STM')">
                  Eliminar Recibo
                </DropdownItem>
              </Dropdown>
              <Grid
                :actionConditional="getActions"
                :columns="columns"
                :data="conceptsByType"
                :key="index"
                :paginated="false"
                :showAdd="
                  index === 0 && !cannotChange && $can('AddPerdeducConcept', 'STM') && implemented
                "
                customEmptyText="No hay conceptos para este recibo de nómina."
                addText="Agregar concepto"
                class="grid-perdeducs"
                v-bind:class="{
                  'hide-headers': index !== 0,
                  'no-button': cannotChange || !$can('AddPerdeducConcept', 'STM') || !implemented,
                }"
                v-for="(conceptsByType, index) in getDataByType(payrollInformation.perdeducs)"
              />
              <div class="grid-perdeducs hide-headers" v-if="hasConcepts">
                <table class="pw-datagrid">
                  <thead class="pw-datagrid__head">
                    <tr>
                      <th :key="index" v-for="(column, index) in columns">
                        <span>{{ column.title }}</span>
                      </th>
                      <th style="width: 30px"></th>
                    </tr>
                  </thead>
                  <tbody class="pw-datagrid__body">
                    <tr class="pw-datagrid__row pw-datagrid__row__total">
                      <td>Pago Neto</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ calculateNetPayment() }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Column>
          </Layout>
        </TabStripItem>
        <TabStripItem
          :key="2"
          v-if="payrollInformation.schemas.length > 1"
          title="Global"
          @click.exact="getGlobalData()"
        >
          <Column class="info-global" size="12">
            <span class="mdi mdi-information">
              <span>
                El resumen generado en este aparatado es únicamente de carácter informativo.
              </span>
            </span>
          </Column>

          <Layout v-if="globalData">
            <Column size="8">
              <div class="calculation-actions block" v-if="hasConcepts">
                <span>{{ `No. de cálculo: ${calculationNumber}` }}</span>

                <Button
                  :disabled="calculationNumber === 1"
                  @click="getPayrollInfoByCalculationNo(-1)"
                  class="custom-button"
                  color="secondary-outlined"
                  icon="chevron-left"
                  v-bind:class="{
                    'custom-button__disabled': calculationNumber === 1,
                  }"
                />
                <Button
                  :disabled="calculationNumber === globalData.noOfCalculations"
                  @click="getPayrollInfoByCalculationNo(1)"
                  class="custom-button"
                  color="secondary-outlined"
                  icon="chevron-right"
                  v-bind:class="{
                    'custom-button__disabled': calculationNumber === globalData.noOfCalculations,
                  }"
                />
              </div>
            </Column>
          </Layout>
          <Layout v-if="globalData">
            <Column size="12">
              <Grid
                :columns="columns"
                :data="conceptsByType"
                :key="index"
                :paginated="false"
                :showAdd="false"
                customEmptyText="No hay conceptos por mostrar!."
                class="grid-perdeducs no-button"
                v-bind:class="{ 'hide-headers': index !== 0 }"
                v-for="(conceptsByType, index) in getDataByType(globalData.perdeducs)"
              />
              <div class="grid-perdeducs hide-headers" v-if="hasConcepts">
                <table class="pw-datagrid">
                  <thead class="pw-datagrid__head">
                    <tr>
                      <th :key="index" v-for="(column, index) in columns">
                        <span>{{ column.title }}</span>
                      </th>
                      <th style="width: 30px"></th>
                    </tr>
                  </thead>
                  <tbody class="pw-datagrid__body">
                    <tr class="pw-datagrid__row pw-datagrid__row__total">
                      <td>Pago Neto</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ calculateNetPayment(true) }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Column>
          </Layout>
        </TabStripItem>
      </TabStrip>
    </Frame>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import numbro from 'numbro';
// import {getPayrollKardex} from './apiGet'

export default {
  name: 'PayrollReceipt',
  data() {
    return {
      loading: false,
      columns: [
        {
          title: 'Concepto',
          template: (item) =>
            item.calculated
              ? item.conceptDescription
              : `${item.conceptCode} - ${this.transform(item.conceptDescription)}`,
        },
        {
          title: 'Código SAT',
          template: (item) =>
            item.calculated ? '' : `${item.satConceptCode} - ${item.satConceptDescription}`,
        },
        {
          title: 'Gravado',
          field: 'gravado',
          format: 'c2',
        },
        {
          title: 'Exento',
          field: 'exempt',
          format: 'c2',
        },
        {
          title: 'Importe',
          field: 'total',
          format: 'c2',
        },
      ],
      actions: [],
      payrollInformation: null,
      calculationNumber: 1,
      hasConcepts: true,
      globalData: null,
      implemented: false,
    };
  },
  mounted() {
    this.getPayrollInfo();
    this.$root.$on('reloadData', () => {
      this.getPayrollInfo();
    });

    if (this.$can('EditPerdeducConcept', 'STM') && this.implemented)
      this.actions.push({
        text: 'Editar Concepto',
        callback: () => {},
      });

    if (this.$can('DeletePerdeducConcept', 'STM'))
      this.actions.push({
        text: 'Eliminar Concepto',
        callback: this.deleteConcept,
      });
  },
  methods: {
    onChange(index) {
      if (index === this.$refs.tabs.tabItems.length - 1) {
        this.getGlobalData();
        return;
      }

      this.getPayrollInfoBySchema(this.payrollInformation.schemas[index]);
    },
    getCurrentSchema() {
      if (this.payrollInformation.schemas.length === 1) return 0;
      const schema = this.payrollInformation.schemas.find(
        (s) => s.payrollSchemaDescription === this.payrollInformation.payrollSchemaDescription
      );

      return schema.payrollSchemaId - 1;
    },
    getPayrollInfo() {
      this.loading = true;
      const { id } = this.$route.params;

      this.getAll('Payroll', `${id}`, 'stamp').then((data) => {
        this.payrollInformation = data;
        this.calculationNumber = data.calculationNumber;
        this.loading = false;
      });

      // setInterval(() => {
      //   console.log('initializing data')
      //   this.payrollInformation = getPayrollKardex(id);
      //   this.loading = false;
      // }, 600);
    },
    getGlobalData() {
      this.calculationNumber = 1;

      const employeeID = parseInt(this.payrollInformation.idEmployee);
      const paymentPeriod = this.payrollInformation.idPaymentPeriod;

      this.getAll(`Payroll/${employeeID}/${paymentPeriod}`, this.calculationNumber, 'stamp').then(
        (data) => {
          this.globalData = data;
        }
      );
    },
    getPayrollInfoBySchema(schema) {
      this.globalData = null;
      if (schema.payrollSchemaCode === this.payrollInformation.payrollSchemaCode) return;

      this.calculationNumber = 1;
      // this.payrollInformation = getPayrollKardex(schema.payrollEmployeeId);
      this.getAll('Payroll', `${schema.payrollEmployeeId}`, 'stamp').then((data) => {
        this.payrollInformation = data;
        this.loading = false;
        this.$router.replace({ params: { id: data.payrollEmployeeId } });
      });
    },
    getPayrollInfoByCalculationNo(step) {
      this.calculationNumber += step;
      const found = this.payrollInformation.calculations.find(
        (p) => p.calculationNumber === this.calculationNumber
      );

      // this.payrollInformation = getPayrollKardex(found.payrollEmployeeId);
      this.getAll('Payroll', `${found.payrollEmployeeId}`, 'stamp').then((data) => {
        this.payrollInformation = data;
        this.loading = false;
        this.$router.replace({ params: { id: data.payrollEmployeeId } });
      });
    },
    getActions(item) {
      if (item.calculated || this.cannotChange) return [];

      return this.actions;
    },
    getTitleAction() {
      const pi = this.payrollInformation;
      let titleAction = '';

      if (pi) {
        titleAction = pi.idEmployee;
        titleAction += ' - ';

        const name = `${this.transform(pi.name)} ${this.transform(
          pi.middleName || ''
        )} ${this.transform(pi.lastName)} ${this.transform(pi.lastName2 || '')}`;

        titleAction += name;
      }
      return titleAction;
    },
    transform(text) {
      if (text === '') return '';
      const lc = text.toLowerCase();

      return lc.charAt(0).toUpperCase() + lc.slice(1);
    },
    getStatusColor(code) {
      const statuses = [
        {
          code: 'STAMPED',
          color: 'success',
        },
        {
          code: 'CANCELLED',
          color: 'quinary',
        },
        {
          code: 'FAILED',
          color: 'color2',
        },
        {
          code: 'INPROC',
          color: 'primary',
        },
        {
          code: 'PENDING',
          color: 'primary',
        },
        {
          code: 'CANCELPROCESS',
          color: 'quinary',
        },
      ];

      const status = statuses.find((x) => x.code === code) || { color: 'color1' };
      return status.color;
    },
    getDataByType(data) {
      if (data === null || data.length === 0) {
        this.hasConcepts = false;
        return [[]];
      }

      const types = ['PERC', 'DEDU', 'OTRPAG'];
      const typesDesc = ['percepciones', 'deducciones', 'otros pagos'];
      const dataByType = [];

      for (const [index, type] of types.entries()) {
        dataByType.push(data.filter((d) => d.satConceptTypeCode === type));

        let totalGravado = 0;
        let totalExempt = 0;
        let total = 0;
        dataByType[index].forEach((item) => {
          totalGravado += item.gravado;
          totalExempt += item.exempt;
          total += item.total;
        });

        dataByType[index].push({
          calculated: true,
          conceptDescription: `Total ${typesDesc[index]}`,
          gravado: totalGravado,
          exempt: totalExempt,
          total: total,
        });
      }

      this.hasConcepts = true;
      return dataByType.filter((d) => d.length !== 1);
    },
    calculateNetPayment(global = false) {
      let total = 0;
      if (global) {
        this.globalData.perdeducs.forEach((perdeduc) => {
          total += perdeduc.total;
        });
      } else {
        this.payrollInformation.perdeducs.forEach((perdeduc) => {
          if (perdeduc.satConceptTypeCode === 'DEDU') {
            total -= perdeduc.total;
          } else {
            total += perdeduc.total;
          }
        });
      }

      return numbro(total).formatCurrency({
        mantissa: 2,
        thousandSeparated: true,
      });
    },
    deleteConcept(item) {
      this.deleteItem(
        `Payroll/${this.payrollInformation.payrollEmployeeId}`,
        item.payrollPerdeducId,
        '¿Desea eliminar el concepto?',
        'Al eliminar el concepto podrían ocurrir afectaciones a procesos relacionados.',
        'stamp',
        {},
        'Eliminado exitosamente'
      );
    },
    onStamp() {
      this.$parent.stampReceipts(null, this.payrollInformation.payrollEmployeeId);
    },
    onPreviousReceipts() {},
    onDeleteConcepts() {
      this.$parent.onDeleteConcepts(this.payrollInformation.payrollEmployeeId);
    },
    onDelete() {
      this.$parent.onDelete(this.payrollInformation.payrollEmployeeId);
    },
  },
  computed: {
    cannotChange() {
      return ['STAMPED', 'INPROC', 'CANCELPROCESS'].includes(this.payrollInformation.statusCode);
    },
    canStamp() {
      return !['STAMPED', 'INPROC', 'CANCELPROCESS'].includes(this.payrollInformation.statusCode);
    },
    hasPrevious() {
      return false;
    },
    availableActions() {
      return (
        (this.$can('StampPayroll', 'STM') && this.canStamp) ||
        (this.$can('ViewPayrolls', 'STM') && this.hasPrevious) ||
        this.$can('DeletePerdeducConcept', 'STM') ||
        this.$can('DeletePayroll', 'STM')
      );
    },
  },
};
</script>

<style lang="scss">
.calculation-actions {
  position: absolute;
  margin-top: 2px;
  margin-bottom: 0;
  span {
    color: #5b89f2;
    font-weight: 600;
  }
  &.block {
    position: relative;
    margin-bottom: 16px;
    padding-bottom: 0;
  }
}

.info-global {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 7px;
  background-color: #dce7ff;

  span {
    //icon
    font-size: 120%;
    color: #5185fe;
    position: relative;
    display: inline;
    vertical-align: baseline;
    margin: 0 0.125em;
    padding: 0;

    span {
      color: #707070;
      font-size: 14px !important;
      vertical-align: middle;
    }
  }
}

.row-overflow {
  /*white-space: nowrap;*/
  /*overflow:hidden;*/
  /*text-overflow: ellipsis;*/
}
.row-overflow:hover {
  text-overflow: clip;
  white-space: normal;
}

.kardex-actions {
  position: relative;
  float: right;
  &.block {
    margin-top: -49px;
    /*margin-bottom: 16px;*/
    padding-bottom: 0;
  }
}

.error-detail {
  display: flex;
  background-color: #fff4da;
  margin-top: 10px;
  padding: 10px;
}

.custom-button {
  margin-left: 16px;

  & + .pw-button {
    margin-left: 3.5px;
  }

  padding: 0.425rem 0.55rem;
  &.pw-button-only-icon {
    width: 31.78px;
    height: 31.78px;
    border-color: #acc4f8;
    &:focus:not(:hover),
    &:active:not(:hover) {
      border-color: #acc4f8;
    }
  }

  &__disabled {
    cursor: auto;
    &.pw-button-only-icon {
      &:hover {
        border-color: #acc4f8;
      }
    }
    .pw-button-icon {
      i.mdi {
        color: #acc4f8;
      }
    }
  }
  .pw-button-icon {
    i.mdi {
      font-size: 1.45rem;
    }
  }
}

.grid-perdeducs {
  .table-actions {
    top: -14px;
    margin-bottom: 18px;
  }

  .pw-datagrid {
    table-layout: fixed;

    &__head {
      tr {
        th:nth-last-child(2),
        th:nth-last-child(3),
        th:nth-last-child(4) {
          text-align: right;
          width: 12%;
        }
      }

      th {
        color: #333333;
        background-color: #f4f5f7;
      }
    }

    &__body {
      .pw-datagrid__row:nth-last-child(1) {
        td {
          font-weight: bold;
          /*line-height: 22px;*/
        }
      }

      .pw-datagrid__row {
        td:nth-last-child(2),
        td:nth-last-child(3),
        td:nth-last-child(4) {
          text-align: right;
        }

        td {
          font-size: 15px;
          color: #333333;
          background-color: #fff;
        }

        &__total {
          td {
            color: #294380;
          }
        }
      }
    }
  }

  &.hide-headers {
    .pw-datagrid {
      &__head {
        height: 1px;

        tr {
          height: 1px;
        }

        th {
          visibility: hidden;
          border: none;
          padding-top: 0;
          padding-bottom: 0;
          line-height: 0;
          height: 0 !important;
        }
      }
    }

    .table-actions {
      margin-bottom: 0;
    }
  }

  &.no-button {
    .table-actions {
      margin-bottom: 0;
    }
  }
}
</style>
